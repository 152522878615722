@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.myswitch {
  .out {
    cursor: pointer;
    height: 24px;
    width: auto;
    border: 1px solid rgba(221, 218, 236, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 2px;
    .inner-item {
      height: 20px;
      line-height: 20px;
      width: 50%;
      font-size: 14px;
      padding: 0 5px;
    }
  }
}
